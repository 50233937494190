export default [ 
    { 'name': 'Abia', 'iso2': 'AB', 'fips_code': '45', 'population': '3,727,300', 'size': null, 'official_language': null, 'region': 'South East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Abia', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Adamawa', 'iso2': 'AD', 'fips_code': '35', 'population': '5,527,800', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Adamawa', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Akwa Ibom', 'iso2': 'AK', 'fips_code': '21', 'population': '5,482,200', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Akwa%20Ibom', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Anambra', 'iso2': 'AN', 'fips_code': '25', 'population': '5,527,800', 'size': null, 'official_language': null, 'region': 'South East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Anambra', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Bauchi', 'iso2': 'BA', 'fips_code': '46', 'population': '6,537,300', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Bauchi', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Bayelsa', 'iso2': 'BY', 'fips_code': '52', 'population': '2,278,000', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Bayelsa', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Benue', 'iso2': 'BE', 'fips_code': '26', 'population': '5,741,800', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Benue', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Borno', 'iso2': 'BO', 'fips_code': '27', 'population': '5,860,200', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Borno', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Cross River', 'iso2': 'CR', 'fips_code': '22', 'population': '3,866,300', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Cross%20River', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Delta', 'iso2': 'DE', 'fips_code': '36', 'population': '5,663,400', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Delta', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Ebonyi', 'iso2': 'EB', 'fips_code': '53', 'population': '2,880,400', 'size': null, 'official_language': null, 'region': 'South East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Ebonyi', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Edo', 'iso2': 'ED', 'fips_code': '37', 'population': '4,235,600', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Edo', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Ekiti', 'iso2': 'EK', 'fips_code': '54', 'population': '3,270,800', 'size': null, 'official_language': null, 'region': 'South West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Ekiti', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Enugu', 'iso2': 'EN', 'fips_code': '47', 'population': '4,411,100', 'size': null, 'official_language': null, 'region': 'South East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Enugu', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Federal Capital Territory', 'iso2': 'FC', 'fips_code': '11', 'population': '3,564,100', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Federal%20Capital%20Territory', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Gombe', 'iso2': 'GO', 'fips_code': '55', 'population': '3,257,000', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Gombe', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Imo', 'iso2': 'IM', 'fips_code': '28', 'population': '5,408,800', 'size': null, 'official_language': null, 'region': 'South East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Imo', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Jigawa', 'iso2': 'JI', 'fips_code': '39', 'population': '5,828,200', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Jigawa', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Kaduna', 'iso2': 'KD', 'fips_code': '23', 'population': '8,252,400', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Kaduna', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Kano', 'iso2': 'KN', 'fips_code': '29', 'population': '13,076,900', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Kano', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Katsina', 'iso2': 'KT', 'fips_code': '24', 'population': '7,831,300', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Katsina', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Kebbi', 'iso2': 'KE', 'fips_code': '40', 'population': '4,440,000', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Kebbi', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Kogi', 'iso2': 'KO', 'fips_code': '41', 'population': '4,473,500', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Kogi', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Kwara', 'iso2': 'KW', 'fips_code': '30', 'population': '3,192,900', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Kwara', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Lagos', 'iso2': 'LA', 'fips_code': '05', 'population': '12,550,600', 'size': null, 'official_language': null, 'region': 'South West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Lagos', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Nasarawa', 'iso2': 'NA', 'fips_code': '56', 'population': '2,523,400', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Nasarawa', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Niger', 'iso2': 'NI', 'fips_code': '31', 'population': '5,556,200', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Niger', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Ogun', 'iso2': 'OG', 'fips_code': '16', 'population': '5,217,700', 'size': null, 'official_language': null, 'region': 'South West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Ogun', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
    { 'name': 'Ondo', 'iso2': 'ON', 'fips_code': '48', 'population': '4,671,700', 'size': null, 'official_language': null, 'region': 'South West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Ondo', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } },
     { 'name': 'Osun', 'iso2': 'OS', 'fips_code': '42', 'population': '4,705,600', 'size': null, 'official_language': null, 'region': 'South West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Osun', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Oyo', 'iso2': 'OY', 'fips_code': '32', 'population': '7,840,900', 'size': null, 'official_language': null, 'region': 'South South', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Oyo', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Plateau', 'iso2': 'PL', 'fips_code': '49', 'population': '4,200,400', 'size': null, 'official_language': null, 'region': 'North Central', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Plateau', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Sokoto', 'iso2': 'SO', 'fips_code': '51', 'population': '4,998,100', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Sokoto', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Taraba', 'iso2': 'TA', 'fips_code': '43', 'population': '3,066,800', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Taraba', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Yobe', 'iso2': 'YO', 'fips_code': '44', 'population': '3,294,100', 'size': null, 'official_language': null, 'region': 'North East', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Yobe', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } }, 
     { 'name': 'Zamfara', 'iso2': 'ZA', 'fips_code': '57', 'population': '4,515,400', 'size': null, 'official_language': null, 'region': 'North West', 'href': { 'self': 'https://restfulcountries.com/api/v1/countries/Nigeria/states/Zamfara', 'country': 'https://restfulcountries.com/api/v1/countries/Nigeria' } } 
    ]