<template>
  <main class="flex bg-transparent min-h-screen xl:px-6 lg:px-6 md:px-8 px-0">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main class="pl-3 mt-4 mb-12 xl:pr-40 lg:pr-40 md:pr-3 pr-3">
        <section class="flex items-center my-5">
          <img
            src="@/assets/images/arrow.svg"
            alt="back to merchants"
            class="cursor-pointer mr-2"
            @click="$router.go(-1)"
          />
          <h1 class="text-xl font-bold capitalize ml-2 dark:text-border-color">
            Order Confirmation
          </h1>
        </section>
        <section class="flex xl:flex-row lg:flex-col md:flex-col flex-col">
          <aside
            class="
              xl:w-2/5
              lg:w-full
              md:w-full
              w-full
              text-legend-gray
              dark:text-border-color
              flex flex-col
              justify-between
            "
          >
            <div>
              <div
                class="
                  bg-white
                  dark:bg-dark-mode-primary
                  rounded-t-md
                  p-6
                  text-legend-gray
                  dark:text-border-color
                "
              >
                <h1
                  class="
                    text-xl
                    font-semibold
                    text-legend-black
                    dark:text-border-color
                  "
                >
                  Order Summary
                </h1>
                <div class="flex justify-between my-5">
                  <div>Subtotal</div>
                  <div>
                    ₦{{
                      $route.name === 'CartCheckout'
                        ? subTotal
                        : $route.query.price | moneyFormat
                    }}
                  </div>
                </div>
                <div class="flex justify-between my-5">
                  <div>Promo code</div>
                  <div>none</div>
                </div>
              </div>
              <div
                class="
                  flex
                  justify-between
                  p-6
                  shadow
                  rounded-b-md
                  text-legend-black
                  dark:text-border-color dark:bg-dark-mode-primary
                  border-t
                  dark:border-border-color
                "
              >
                <div class="text-lg font-semibold">Grand Total</div>
                <div class="text-2xl font-bold">
                  ₦{{
                    $route.name === 'CartCheckout'
                      ? subTotal
                      : $route.query.price | moneyFormat
                  }}
                </div>
              </div>
              <div class="mt-10 mb-3 font-semibold">
                How do you want to receive this?
              </div>
              <div
                class="
                  text-legend-gray
                  dark:text-border-color
                  uppercase
                  mb-12
                  grid grid-cols-2
                  gap-10
                "
              >
                <button
                  class="
                    py-5
                    px-7
                    rounded-lg
                    shadow-lg
                    bg-white
                    dark:bg-dark-mode-primary
                    flex
                    items-center
                    border
                  "
                  :class="{
                    'border-primary-color': deliveryMethod === 'Delivery',
                  }"
                  @click="deliveryMethod = 'Delivery'"
                >
                  <div
                    v-if="deliveryMethod === 'Delivery'"
                    style="width: 18px; height: 18px"
                    class="
                      bg-primary-color
                      rounded
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <img
                      src="@/assets/images/check-mark.svg"
                      alt="via delivery"
                    />
                  </div>
                  <img
                    v-else
                    src="@/assets/images/checkbox-unchecked.svg"
                    alt="via pickup"
                  />
                  <span class="font-bold text-xs ml-3.5">Delivery</span>
                </button>
                <button
                  class="
                    py-5
                    px-7
                    rounded-lg
                    shadow-lg
                    bg-white
                    dark:bg-dark-mode-primary
                    flex
                    items-center
                    border
                  "
                  :class="{
                    'border-primary-color': deliveryMethod === 'Pickup',
                  }"
                  @click="deliveryMethod = 'Pickup'"
                >
                  <div
                    v-if="deliveryMethod === 'Pickup'"
                    style="width: 18px; height: 18px"
                    class="
                      bg-primary-color
                      rounded
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <img
                      src="@/assets/images/check-mark.svg"
                      alt="via pickup"
                    />
                  </div>
                  <img
                    v-else
                    src="@/assets/images/checkbox-unchecked.svg"
                    alt="via delivery"
                  />
                  <span class="font-bold text-xs ml-3.5">Pickup</span>
                </button>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-10 mt-12 text-md">
              <router-link
                :to="{ name: 'Purchases' }"
                class="
                  border border-primary-color
                  rounded-lg
                  text-primary-color text-center
                  py-3
                "
                :class="{ 'inactive-state': submitting }"
              >
                Continue Shopping
              </router-link>
              <button
                class="
                  border border-primary-color
                  bg-primary-color
                  rounded-lg
                  text-white
                  py-3
                "
                :class="{ 'inactive-state': submitting }"
                @click="inputPin = true"
              >
                Place my Order
              </button>
            </div>
          </aside>
          <section
            v-if="userData && deliveryMethod === 'Delivery'"
            class="xl:ml-12 lg:ml-0 md:ml-0 ml-0 xl:my-0 lg:my-0 md:my-0 my-24"
          >
            <form
              class="
                bg-white
                dark:bg-dark-mode-primary
                rounded-md
                xl:p-12
                lg:p-12
                md:p-12
                p-4
              "
            >
              <h1 class="text-xl font-semibold">Delivery Information</h1>
              <section class="flex my-6">
                <div
                  class="
                    text-legend-gray
                    dark:text-border-color
                    capitalize
                    w-1/2
                    mr-6
                  "
                >
                  <label class="font-semibold text-sm mb-1">First name</label>
                  <input
                    v-model="userData.first_name"
                    type="text"
                    placeHolder="Enter first name"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                      capitalize
                    "
                  />
                </div>
                <div
                  class="
                    text-legend-gray
                    dark:text-border-color
                    capitalize
                    w-1/2
                  "
                >
                  <label class="font-semibold text-sm mb-1">Last name</label>
                  <input
                    v-model="userData.last_name"
                    type="text"
                    placeHolder="Enter last name"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                      capitalize
                    "
                  />
                </div>
              </section>
              <div
                class="
                  text-legend-gray
                  dark:text-border-color
                  mb-8
                  capitalize
                  w-full
                "
              >
                <label class="font-semibold text-sm mb-1">Email address</label>
                <input
                  v-model="userData.email"
                  type="text"
                  placeHolder="e.g ayo@legendpay.ng"
                  class="
                    w-full
                    border border-border-color
                    rounded-lg
                    flex
                    items-center
                    p-3
                    bg-transparent
                    focus:outline-none
                  "
                />
              </div>
              <div class="flex items-center">
                <div
                  class="
                    xl:w-1/3
                    lg:w-1/3
                    md:w-2/5
                    w-2/5
                    flex-shrink-0
                    mb-8
                    relative
                  "
                >
                  <div class="w-full relative">
                    <label
                      class="
                        text-legend-gray
                        dark:text-border-color
                        text-sm
                        font-semibold
                        block
                        leading-relaxed
                      "
                      >Country code</label
                    >
                    <div
                      class="
                        flex
                        items-center
                        border border-border-color
                        w-full
                        rounded-lg
                        py-3
                        px-1
                      "
                    >
                      <img
                        :src="selectedCountryFlag"
                        alt=""
                        class="w-8 rounded shadow-md"
                      />
                      <vue-autosuggest
                        v-model="query"
                        :suggestions="filteredOptions"
                        @click="clickHandler"
                        @selected="onSelected"
                        :get-suggestion-value="getSuggestionValue"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: selected,
                        }"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <img
                            :style="{
                              display: 'flex',
                              width: '25px',
                              height: '25px',
                              borderRadius: '15px',
                              marginRight: '10px',
                            }"
                            :src="suggestion.item.href.flag"
                          />
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.name }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </div>
                  </div>
                  <!-- <img
                    src="@/assets/images/down-chevron.svg"
                    alt="select country dialling code"
                    class="w-3 absolute right-4 top-11"
                  /> -->
                </div>
                <div
                  class="
                    text-legend-gray
                    dark:text-border-color
                    mb-8
                    capitalize
                    w-2/3
                    ml-6
                  "
                >
                  <label class="font-semibold text-sm mb-1">phone number</label>
                  <input
                    v-model="userData.phone"
                    type="text"
                    placeHolder="e.g +2348000000000"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                    "
                  />
                </div>
              </div>
              <section class="flex items-start">
                <div
                  class="
                    w-1/5
                    flex-shrink-0
                    text-legend-gray
                    dark:text-border-color
                    mb-8
                    capitalize
                  "
                >
                  <label class="font-semibold text-sm mb-1">Street no.</label>
                  <input
                    v-model="userData.street_number"
                    type="text"
                    placeHolder="e.g 123"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                    "
                  />
                </div>
                <div
                  class="
                    w-4/5
                    text-legend-gray
                    dark:text-border-color
                    mb-8
                    capitalize
                    ml-6
                  "
                >
                  <label class="font-semibold text-sm mb-1">Street name</label>
                  <input
                    v-model="userData.street_name"
                    type="text"
                    placeHolder="Enter street name"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                    "
                  />
                </div>
              </section>
              <section class="flex">
                <div
                  class="
                    text-legend-gray
                    dark:text-border-color
                    mb-8
                    capitalize
                    w-1/2
                    mr-6
                    relative
                  "
                >
                  <label class="font-semibold text-sm mb-1">State</label>
                  <input
                    v-model="userData.state"
                    type="text"
                    readonly
                    placeHolder="Enter your state"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                    "
                    @click="showStatesOptions = true"
                  />
                  <chevron-down-icon
                    size="1.8x"
                    class="
                      custom-class
                      pr-2
                      text-legend-gray
                      dark:text-border-color
                      absolute
                      right-2
                      top-9
                    "
                  ></chevron-down-icon>
                  <ul
                    v-if="showStatesOptions"
                    class="
                      border border-gray-200
                      shadow-md
                      rounded-md
                      bg-white
                      absolute
                      left-0
                      top-full
                      w-full
                      z-50
                      max-h-48
                      overflow-y-scroll
                      overscroll-contain
                    "
                  >
                    <li
                      v-for="(state, index) in states"
                      :key="index"
                      class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                      @click="assignSelectedState(state.name)"
                    >
                      {{ state.name }}
                    </li>
                  </ul>
                </div>
                <div
                  class="
                    text-legend-gray
                    dark:text-border-color
                    mb-8
                    capitalize
                    w-1/2
                    relative
                  "
                >
                  <label class="font-semibold text-sm mb-1">Country</label>
                  <input
                    v-model="userData.country"
                    type="text"
                    readonly
                    placeHolder="Enter your country"
                    class="
                      w-full
                      border border-border-color
                      rounded-lg
                      flex
                      items-center
                      p-3
                      bg-transparent
                      focus:outline-none
                    "
                    @click="showCountriesOption = true"
                  />
                  <chevron-down-icon
                    size="1.8x"
                    class="
                      custom-class
                      pr-2
                      text-legend-gray
                      dark:text-border-color
                      absolute
                      right-1
                      top-9
                    "
                  ></chevron-down-icon>
                  <ul
                    v-if="showCountriesOption"
                    class="
                      border border-gray-200
                      shadow-md
                      rounded-md
                      bg-white
                      absolute
                      left-0
                      top-full
                      w-full
                      z-50
                      max-h-40
                      overflow-y-scroll
                      overscroll-contain
                    "
                  >
                    <li
                      v-for="(country, index) in suggestions"
                      :key="index"
                      class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                      @click="assignSelectedCountry(country.name)"
                    >
                      {{ country.name }}
                    </li>
                  </ul>
                </div>
              </section>
            </form>
          </section>
        </section>
      </main>
    </section>
    <custom-modal v-if="inputPin">
      <TransactionPin
        slot="cart"
        :has-pin="user.has_pin"
        @closeModal="inputPin = false"
        @place-order="placeOrder"
        @set-pin="openSetTransactionPinModal"
      />
    </custom-modal>
    <custom-modal v-if="setPin">
      <SetPin
        slot="cart"
        @closeModal="setPin = false"
        @change-pin="setTransactionPin"
      />
    </custom-modal>
    <!-- Enter transaction pin -->
    <custom-modal v-if="inputPin">
      <section slot="cart" class="p-8">
        <x-icon
          size="1.3x"
          class="
            custom-class
            mb-4
            -ml-6
            -mt-1
            text-primary-color
            cursor-pointer
          "
          @click="closeModal"
        ></x-icon>
        <div>
          <label
            class="
              text-legend-gray
              dark:text-border-color
              font-semibold
              block
              leading-relaxed
            "
            >Transaction Pin</label
          ><input
            v-model="transactionPin"
            type="password"
            placeholder="****"
            class="
              text-seconadry-border
              border border-border-color
              rounded-lg
              w-full
              p-3
              focus:outline-none
              bg-transparent
            "
          />
          <ValidationMessage v-if="loading && !$v.transactionPin.required">
            <span slot="message">Your transaction pin is required.</span>
          </ValidationMessage>
        </div>
        <div v-if="user && !user.has_pin" class="flex items-center">
          <span>Don't have transaction pin?</span>
          <router-link
            :to="{ name: 'Profile', params: { username: user.username } }"
            class="
              text-primary-color
              font-semibold
              cursor-pointer
              capitalize
              ml-2
            "
          >
            Click here
          </router-link>
        </div>
        <custom-button :loading="submitting" @sendAction="placeOrder">
          <span slot="button-text">Send</span>
        </custom-button>
      </section>
    </custom-modal>
  </main>
</template>

<script>
import TransactionPin from '@/components/TransactionPin.vue'
import SetPin from '@/components/SetTransferPin.vue'
import listOfCountries from '@/util/countries'
import listOfStates from '@/util/listOfStates'
import { VueAutosuggest } from 'vue-autosuggest'
import { ChevronDownIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'
import { required } from 'vuelidate/lib/validators'
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'Checkout',
  components: {
    VueAutosuggest,
    ChevronDownIcon,
    TransactionPin,
    SetPin,
    XIcon,
  },
  mounted() {
    this.userData = this.user
  },
  data() {
    return {
      userData: null,
      productSummary: null,
      inputPin: false,
      transactionPin: null,
      setPin: false,
      selectCountryCode: false,
      query: '',
      selected: '234',
      selectedCountryFlag:
        'https://restfulcountries.com/assets/images/flags/Nigeria.png',
      suggestions: listOfCountries,
      states: listOfStates,
      showStatesOptions: false,
      showCountriesOption: false,
      notification: null,
      loading: false,
      submitting: false,
      deliveryMethod: 'Pickup',
    }
  },
  validations() {
    return {
      transactionPin: { required },
    }
  },
  computed: {
    ...mapGetters(['cart', 'user']),
    subTotal() {
      let subTotal = 0

      this.cart.forEach((tick) => {
        subTotal += Number(tick.product.price)
      })

      return subTotal
    },
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter((option) => {
            return (
              option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
          }),
        },
      ]
    },
  },
  methods: {
    closeModal() {
      this.inputPin = false
      this.submitting = false
      this.loading = false
    },
    openSetTransactionPinModal() {
      this.inputPin = false
      this.setPin = true
    },
    async setTransactionPin(payload) {
      try {
        const { status, data } = await this.axios.post('/account/wallet/pin', {
          pin: payload.pin,
          confirm_pin: payload.pin_confirmation,
        })

        if (status >= 200 && status < 300) {
          this.setPin = false
          this.notification = {
            type: 'success',
            message: `
            <h1>${data.message}</h1>
            <p>You can now place your order</p>
            `,
          }
        }
      } catch (error) {
        this.setPin = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.password[0],
          }
        }
      }
    },
    clickHandler() {
      this.selected = null
    },
    onSelected(item) {
      this.selected = item.item.phone_code
      this.selectedCountryFlag = item.item.href.flag
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.phone_code.charAt(0) === '+'
        ? suggestion.item.phone_code
        : `+${suggestion.item.phone_code}`
    },
    async productCheckout() {
      try {
        if (this.loading) {
          return
        }
        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status } = await this.axios.post(
          `/account/products/${this.$route.query.id}/checkout`,
          {
            address: `${this.user.street_number}, ${this.user.street_name}, ${this.user.state}, ${this.user.country}`,
            location: 'Pickup',
            quantity: this.$route.query.quantity,
            description: this.$route.query.product,
            pin: this.transactionPin,
          }
        )

        if (status === 200) {
          this.placingOrder = true
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.submitting = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async cartCheckout() {
      try {
        if (this.loading) {
          return
        }

        this.loading = true
        const { status } = await this.axios.post('/account/cart/checkout', {
          location: this.deliveryMethod,
          address: `${this.userData.street_number}, ${this.userData.street_name}, ${this.userData.state}, ${this.userData.country}`,
          pin: this.transactionPin,
        })

        if (status === 200) {
          this.placingOrder = true
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    placeOrder() {
      console.log(this.userData)
      // Object.keys(this.$route.query).length === 0
      //   ? this.cartCheckout()
      //   : this.productCheckout()
    },
    assignSelectedState(state) {
      this.userData.state = state
      this.showStatesOptions = false
    },
    assignSelectedCountry(country) {
      this.userData.country = country
      this.showCountriesOption = false
    },
  },
}
</script>
