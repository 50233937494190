<template>
  <main class="px-10 h-screen overflow-y-scroll overscroll-contain pb-16">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-6 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl">Input Transaction Pin</h1>
    <p class="text-legend-gray dark:text-border-color my-4">
      Input transaction PIN to authorise this transaction
    </p>
    <div slot="input-fields">
      <div class="mt-8">
        <div
          class="
            flex
            items-center
            justify-between
            text-legend-gray
            dark:text-border-color
            my-3
          "
        >
          <span class="font-semibold">Enter Transaction PIN</span>
        </div>
        <CodeInput
          :loading="false"
          :autoFocus="true"
          class="input"
          :fields="4"
          @complete="onComplete"
        />
      </div>
      <span
        v-if="!hasPin"
        class="
          inline-block
          text-primary-color
          font-semibold
          pt-7
          pb-6
          cursor-pointer
        "
        @click="$emit('set-pin')"
      >
        Set up your Pin
      </span>
    </div>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import CodeInput from 'vue-verification-code-input'

export default {
  name: 'TransactionPin',
  props: {
    hasPin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    XIcon,
    CodeInput,
  },
  data() {
    return {
      notification: null,
    }
  },
  methods: {
    onComplete(value) {
      this.$emit('place-order', value)
    },
  },
}
</script>
