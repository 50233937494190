<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black">Create Transaction PIN</h1>
    <div class="my-3 text-sm mb-8 text-legend-gray">
      Keep your account safe with your secret PIN. Please, don’t share this PIN
      with anyone.
    </div>
    <custom-input
      :inputData="{
        label: 'Secret Pin (4 digit pin)',
        fieldName: 'pin',
        placeHolder: 'Enter pin',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="custom-class pr-2 text-legend-gray eye-icon"
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage v-if="loading && !$v.pin.required" slot="validation">
        <span slot="message">Pin is required.</span>
      </ValidationMessage>
      <ValidationMessage v-if="loading && !$v.pin.maxLength" slot="validation">
        <span slot="message">Pin must be 4 numbers</span>
      </ValidationMessage>
      <ValidationMessage v-if="loading && !$v.pin.numeric" slot="validation">
        <span slot="message">Pin must be only numbers</span>
      </ValidationMessage>
    </custom-input>

    <custom-input
      :inputData="{
        label: 'confirm secret pin',
        fieldName: 'pin_confirmation',
        placeHolder: 'Confirm new pin',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="custom-class pr-2 text-legend-gray eye-icon"
        @click="toggleVisibility"
      ></eye-icon>

      <ValidationMessage
        v-if="loading && !$v.pin_confirmation.sameAsPin"
        slot="validation"
      >
        <span slot="message">Pins do not match.</span>
      </ValidationMessage>
    </custom-input>
    <custom-button :loading="submitting" @sendAction="changePin">
      <span slot="button-text">Confirm Pin</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, EyeIcon } from 'vue-feather-icons'
import { required, maxLength, numeric, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'SetTransferPin',
  components: {
    XIcon,
    EyeIcon,
  },
  data() {
    return {
      pin: '',
      pin_confirmation: '',
      attributeType: 'password',
      loading: false,
      submitting: false,
      comparePin: false,
    }
  },
  validations() {
    return {
      pin: {
        required,
        numeric,
        maxLength: maxLength(4),
      },
      pin_confirmation: {
        sameAsPin: sameAs('pin'),
      },
    }
  },
  methods: {
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    changePin() {
      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.submitting = true

      this.$emit('change-pin', {
        pin: this.pin,
        pin_confirmation: this.pin_confirmation,
      })
    },
    toggleVisibility() {
      const nodes = document.querySelectorAll('.eye-icon')
      if (this.attributeType === 'password') {
        this.attributeType = 'text'
        nodes.forEach((node) => {
          node.classList.remove('text-legend-gray')
          node.classList.add('text-primary-color')
        })
      } else {
        this.attributeType = 'password'
        nodes.forEach((node) => {
          node.classList.remove('text-primary-color')
          node.classList.add('text-legend-gray')
        })
      }
    },
  },
}
</script>
